import _shim from "core-js/shim";
import _runtime from "regenerator-runtime/runtime";
import _escape from "core-js/fn/regexp/escape";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

_shim;
_runtime;
_escape;

if (_global._babelPolyfill) {
  throw new Error("only one instance of babel-polyfill is allowed");
}

_global._babelPolyfill = true;
var DEFINE_PROPERTY = "defineProperty";

function define(O, key, value) {
  O[key] || Object[DEFINE_PROPERTY](O, key, {
    writable: true,
    configurable: true,
    value: value
  });
}

define(String.prototype, "padLeft", "".padStart);
define(String.prototype, "padRight", "".padEnd);
"pop,reverse,shift,keys,values,entries,indexOf,every,some,forEach,map,filter,find,findIndex,includes,join,slice,concat,push,splice,unshift,sort,lastIndexOf,reduce,reduceRight,copyWithin,fill".split(",").forEach(function (key) {
  [][key] && define(Array, key, Function.call.bind([][key]));
});
export default {};